import axios from "axios"
import { uploadFilesUrl } from "../../../utils/constants"
import { IDocument } from "../../../types/work-item-types"
import { normaliseDiacritics } from "../../../utils/strings"
import { IFile } from "../../../types/profile-types"
import _ from "lodash"

export const uploadChunk = async (
  files: File[],
  chunkUuid: string | undefined | null,
  currentFileIndex: number,
  setCurrentFileIndex: (index: number) => void
) => {
  const file: File = files[currentFileIndex]
  let chunkSize = 3.5 * 1024 * 1024 // 3.5 MB chunk size
  let chunks: any = []

  let fileSize = file.size
  let start = 0
  let end = chunkSize

  while (start < fileSize) {
    chunks.push(file.slice(start, end))
    start = end
    end = start + chunkSize
  }

  const authToken = sessionStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/octet-stream",
    Authorization: `Bearer ${authToken}`,
  }
  const params = new URLSearchParams()
  params.set("totalChunks", chunks.length)
  params.set(
    "name",
    chunkUuid!?.concat("***").concat(normaliseDiacritics(file.name))
  )

  const url = uploadFilesUrl.concat(params.toString())
  const a = chunks.map((chunk: any, index: number) => {
    let formData = new FormData()
    formData.append(`chunk`, chunk, `chunk-${index}`)
    return formData
  })
  for (let i = 0; i < a.length; ) {
    await axios.post(url, a[i], { headers }).then((res) => {
      i++
    })
  }
  setCurrentFileIndex(currentFileIndex + 1)
}

export const uploadChunkProfile = async (
  files: IFile[],
  currentFileIndex: number,
  setCurrentFileIndex: (index: number) => void
) => {
  const file: File = files[currentFileIndex].content
  let chunkSize = 3.5 * 1024 * 1024 // 3.5 MB chunk size
  let chunks: any = []

  let fileSize = file.size
  let start = 0
  let end = chunkSize

  while (start < fileSize) {
    chunks.push(file.slice(start, end))
    start = end
    end = start + chunkSize
  }

  const authToken = sessionStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/octet-stream",
    Authorization: `Bearer ${authToken}`,
  }
  const params = new URLSearchParams()
  params.set("totalChunks", chunks.length)
  params.set("name", files[currentFileIndex].nameFile as string)

  const url = uploadFilesUrl.concat(params.toString())
  const a = chunks.map((chunk: any, index: number) => {
    let formData = new FormData()
    formData.append(`chunk`, chunk, `chunk-${index}`)
    return formData
  })
  for (let i = 0; i < a.length; ) {
    await axios.post(url, a[i], { headers }).then((res) => {
      i++
    })
  }
  setCurrentFileIndex(currentFileIndex + 1)
}

export const buildMultipleFilesData = (containerOtherDocs: IDocument[]) => {
  let multiplePdf: IDocument[] = []
  if (containerOtherDocs) {
    containerOtherDocs.forEach((pdf) => {
      const newDocForPost: IDocument = {
        filename: normaliseDiacritics(pdf.filename),
        content: pdf.content.slice(28),
      }
      multiplePdf.push(newDocForPost)
    })
  }

  return multiplePdf
}
